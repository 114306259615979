<div class="lifestyle-area bg-f5f7fa ptb-70"><!--bg-f5f7fa pt-70-->
    <div class="container">
        <div class="section-title">
            <h2 >How CANITUDE Works</h2>
        <!-- <p><strong style="color: var(--mainColor);">FIND AN EXPERT:</strong> Get the information you need, right at home. &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p style="margin-top: 0px; margin-bottom: 0px;"><strong>OR</strong></p> 
        <p><strong  style="color: var(--mainColor);">BE AN EXPERT:</strong> Sign up to share your skills and earn some extra money.</p> -->
            <!-- <h2 class="playfair-display-font">Inspring you to live a healthier lifestyle</h2> -->
            <!-- <p>CANitude is a platform that enables you to develop the "CAN-do" mentality by connecting you with a person that has the skill that you want to develop. You CAN do it yourself – if you have an Expert’s advice.</p> -->
        </div>

        <div class="row d-flex align-items-center justify-content-center flex-row pb-5">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div (click)="scrollToDownload()" style="cursor: pointer;" class="single-banner-box">
                    <div class="icon">
                        <i class="flaticon-experience"></i>
                    </div>
                    <h3>Find An Expert</h3>
                    <p>Get the information you need, right at home.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div (click)="scrollToDownload()" style="cursor: pointer;" class="single-banner-box">
                    <div class="icon">
                       <i class="flaticon-self-growth"></i>
                        <!-- <i class="flaticon-brain-process"></i> -->
                    </div>
                    <h3>Be An Expert</h3>
                    <p>Sign up to share your skills and earn some extra money.</p>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-banner-box">
                    <div class="icon">
                        <i class="flaticon-world"></i>
                    </div>
                    <h3>Lifetime Access</h3>
                    <p>Lorem ipsum dolor sit amet consectets.</p>
                </div>
            </div> -->
        </div>


        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-lifestyle-box">
                    <div class="icon">
                        <i>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512; width:64px; height: 64px" xml:space="preserve">
                                  <g id="_x30_3_Start_The_Online_Session">
                                      <g>
                                          <g>
                                              
                                                  <polygon style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="     312.022,375.066 345.534,453.08 310.17,453.08 276.659,375.066    "/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M495.993,309.163v26.655c0,21.702-17.617,39.268-39.268,39.268h-35.336c4.494,0,8.834-0.766,12.868-2.145l0.051-0.051     c11.285-3.881,20.272-12.868,24.153-24.153l0.051-0.051c1.379-4.034,2.145-8.375,2.145-12.868V98.167     c0-4.34-0.715-8.579-2.043-12.46c-1.736-5.208-4.494-9.906-8.119-13.838c-3.523-3.881-7.813-7.098-12.613-9.294     c-5.004-2.349-10.57-3.626-16.493-3.626h35.336c2.094,0,4.187,0.153,6.179,0.511c5.77,0.868,11.081,3.064,15.727,6.179     c4.341,2.911,8.017,6.689,10.877,11.03c3.115,4.698,5.209,10.059,6.026,15.881c0.256,1.838,0.409,3.728,0.409,5.617v210.996     H495.993z"/>
                                              
                                                  <polyline style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="     347.565,453.08 345.534,453.08 310.17,453.08    "/>
                                              
                                                  <polygon style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="     310.19,453.066 276.648,375.092 199.998,375.092 166.456,453.066    "/>
                                              
                                                  <line style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="103.683" y1="453.066" x2="372.963" y2="453.066"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M460.619,98.166v211.046H16.007V98.166c0-21.684,17.562-39.246,39.246-39.246h366.119     C443.057,58.92,460.619,76.482,460.619,98.166z"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M16.02,309.187v26.644c0,21.683,17.578,39.261,39.261,39.261h366.09c21.683,0,39.261-17.578,39.261-39.261v-26.644H16.02z"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M219.558,128.207l-1.737,112.288c-0.156,10.08-8.372,18.17-18.453,18.17h-96.085c-10.192,0-18.454-8.262-18.455-18.453     L84.817,128.4c-0.001-10.158,8.207-18.407,18.365-18.457l97.833-0.476C211.354,109.416,219.718,117.869,219.558,128.207z"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M151.019,164.848c3.626,6.935,2.311,16.123-3.116,21.762c-5.427,5.639-14.558,7.305-21.626,3.947     c-7.069-3.358-11.545-11.489-10.603-19.258C117.843,153.424,142.671,148.884,151.019,164.848z"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M171.221,150.798c9.831,0.173,19.662,0.346,29.493,0.519"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M171.42,168.208c9.831,0.173,5.702,0.504,15.533,0.677"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M391.807,128.207l-1.737,112.288c-0.156,10.08-8.372,18.17-18.453,18.17h-96.085c-10.192,0-18.454-8.262-18.455-18.453     L257.066,128.4c-0.001-10.158,8.207-18.407,18.365-18.457l97.833-0.476C383.604,109.416,391.967,117.869,391.807,128.207z"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M323.268,164.848c3.626,6.935,2.311,16.123-3.116,21.762c-5.427,5.639-14.558,7.305-21.626,3.947     c-7.069-3.358-11.545-11.489-10.603-19.258C290.092,153.424,314.921,148.884,323.268,164.848z"/>
                                              <g>
                                                  
                                                      <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="      M162.782,258.665c0.699-7.447,0.979-14.933,0.84-22.412c-0.277-14.969-12.725-26.864-27.696-26.864h0      c-14.971,0-27.419,11.895-27.696,26.864c-0.138,7.479,0.141,14.965,0.84,22.412H162.782z"/>
                                                  
                                                      <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="      M335.032,258.665c0.699-7.447,0.979-14.933,0.84-22.412c-0.277-14.969-12.725-26.864-27.696-26.864h0      c-14.971,0-27.419,11.895-27.696,26.864c-0.138,7.479,0.141,14.965,0.84,22.412H335.032z"/>
                                              </g>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M343.471,150.798c9.831,0.173,19.662,0.346,29.493,0.519"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M343.669,168.208c9.831,0.173,5.702,0.504,15.533,0.677"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M371.092,237.782c1.785-13.332,2.248-26.841,1.382-40.264"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M372.804,186.102c0.301-1.984,0.603-3.968,0.904-5.953"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M198.318,242.394c1.001-13.452,0.226-27.035-2.299-40.285"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M196.473,190.208c0.153-1.984,0.305-3.967,0.458-5.951"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M80.316,352.816c39.305-4.135,78.98-4.751,118.395-1.837"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M220.162,352.848c3.864-0.276,7.728-0.552,11.592-0.828"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M215.444,402.646c-5.332,11.398-10.665,22.797-15.997,34.195c15.475-1.937,30.95-3.874,46.425-5.811"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M430.803,111.919c0.956,33.944,1.912,67.888,2.868,101.831"/>
                                              
                                                  <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="     M434.61,232.791c0,3.864,0,7.728,0,11.592"/>
                                              <g>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="284.333" y1="375.086" x2="278.971" y2="380.448"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="306.29" y1="375.086" x2="285.559" y2="395.818"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="316.861" y1="386.371" x2="292.146" y2="411.086"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="323.448" y1="401.742" x2="298.733" y2="426.457"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="330.035" y1="417.01" x2="305.32" y2="441.776"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="336.622" y1="432.329" x2="315.89" y2="453.061"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="343.21" y1="447.648" x2="337.848" y2="453.061"/>
                                              </g>
                                              <g>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="441.507" y1="58.95" x2="437.882" y2="62.576"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="462.903" y1="59.461" x2="450.495" y2="71.869"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="478.631" y1="65.64" x2="458.614" y2="85.708"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="489.507" y1="76.669" x2="460.656" y2="105.52"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.533" y1="92.55" x2="460.656" y2="127.427"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.941" y1="114.048" x2="460.656" y2="149.333"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.941" y1="135.954" x2="460.656" y2="171.24"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.941" y1="157.861" x2="460.656" y2="193.146"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.941" y1="179.767" x2="460.656" y2="215.052"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.941" y1="201.674" x2="460.656" y2="236.959"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.941" y1="223.58" x2="460.656" y2="258.865"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.941" y1="245.486" x2="460.656" y2="280.771"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.941" y1="267.393" x2="460.656" y2="302.678"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.941" y1="289.299" x2="460.656" y2="324.584"/>
                                                  
                                                      <polyline style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="      432.061,375.086 434.256,372.942 434.307,372.891     "/>
                                                  
                                                      <polyline style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="      458.461,348.737 458.512,348.686 495.993,311.154     "/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.993" y1="333.061" x2="453.967" y2="375.086"/>
                                              </g>
                                              <g>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="137.116" y1="209.435" x2="108.214" y2="238.337"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="153.15" y1="215.308" x2="109.797" y2="258.661"/>
                                                  
                                                      <polyline style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="      162.239,228.125 162.239,228.176 131.703,258.661     "/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="163.465" y1="248.805" x2="153.61" y2="258.661"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="312.061" y1="209.691" x2="280.452" y2="241.299"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="326.971" y1="216.737" x2="285.048" y2="258.661"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="335.09" y1="230.474" x2="306.903" y2="258.661"/>
                                                  
                                                      <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="335.55" y1="251.971" x2="328.861" y2="258.661"/>
                                              </g>
                                          </g>
                                          
                                              <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M353.366,350.156c23.925-0.318,47.851-0.636,71.776-0.953"/>
                                      </g>
                                  </g>
                                  <g id="Layer_1">
                                  </g>
                            </svg>
                          </i>
                    </div>
                    <h5><strong><a>1. Create a Profile.</a></strong></h5>
                    <h3>&nbsp;</h3>
                    <!-- <p>You can sign-up for a user account for free by downloading our mobile App or by visiting our website at www.canitude.com. During sign-up for your user account, you will be required to provide us with your full name, email address, mobile phone number, date of birth, location (country), and profile image. 
                        Depending on whether you sign up for an account as a Learner or as an Expert, we may request some additional information from you. 
                        As a Learner, you can connect to people that will show you that you CAN do it yourself without someone doing it for you. You will pay for their services with a credit card or via PayPal. 
                        As an Expert, you will get access to connect to people who simply need your guidance to do the things they need to do. Get paid for your expertise when you provide the advice. 
                    </p> -->
                    <!-- <a routerLink="/courses-1" class="link-btn">Start Now</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-lifestyle-box">
                    <div class="icon">
                        <i>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512; width:64px; height:64px;" xml:space="preserve">
                                <g id="_x30_2_Search_And_Chat_With_A_Teacher">
                                    <g>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M218.984,167.56c-13.433-0.534-26.647-5.603-36.989-14.192s-17.754-20.646-20.746-33.752c-2.31-10.117-2.032-20.68-0.539-30.949    c3.385-23.275,14.013-46.737,33.752-59.526c10.25-6.641,22.309-10.013,34.392-11.795c12.622-1.862,25.653-2.064,38.002,1.144    c12.349,3.209,24.007,10.045,31.538,20.344c5.165,7.064,8.213,15.418,11.119,23.672c5.537,15.729,10.871,32.313,8.306,48.789    c-1.962,12.603-8.482,24.149-16.74,33.87c-8.258,9.721-18.238,17.808-28.223,25.745"/>
                                        <g>
                                            
                                                <polyline style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="     192.323,30.61 192.272,30.661 177.362,45.623 177.311,45.674    "/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="227.251" y1="17.588" x2="161.685" y2="83.154"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="250.638" y1="16.159" x2="159.643" y2="107.154"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="269.379" y1="19.274" x2="186.145" y2="102.508"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="184.817" y1="103.835" x2="162.962" y2="125.691"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="189.055" y1="121.503" x2="170.264" y2="140.295"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="284.545" y1="26.065" x2="207.489" y2="103.069"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="195.183" y1="137.282" x2="180.477" y2="151.988"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="296.238" y1="36.227" x2="263.353" y2="69.112"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="304.613" y1="49.759" x2="270.962" y2="83.41"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="205.038" y1="149.333" x2="193.498" y2="160.874"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="219.898" y1="156.38" x2="209.838" y2="166.44"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="310.638" y1="65.691" x2="281.379" y2="94.95"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="315.745" y1="82.44" x2="293.072" y2="105.112"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="240.323" y1="157.861" x2="229.957" y2="168.227"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="318.502" y1="101.588" x2="250.536" y2="169.554"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="310.74" y1="131.257" x2="271.166" y2="170.831"/>
                                        </g>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M220.184,168.38c-7.416,1.796-15.101,3.767-22.876,5.574c-20.791,4.832-35.857,23.027-36.031,44.371    c-0.135,16.632-0.816,33.26-2.042,49.848"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M323.828,268.173c-1.226-16.588-1.907-33.216-2.042-49.848c-0.174-21.344-15.241-39.539-36.031-44.371    c-7.775-1.807-15.46-3.778-22.876-5.574"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M293.312,103.059c-0.167,1.728-0.334,3.512-0.613,5.24c-2.286,15.942-9.588,32.051-23.133,40.803    c-7.023,4.571-15.273,6.856-23.578,8.083c-8.64,1.282-17.614,1.449-26.087-0.78c-8.417-2.174-16.444-6.856-21.628-13.935    c-3.512-4.849-5.63-10.591-7.581-16.221c-2.732-7.804-5.407-15.886-6.02-24.024c4.459,1.003,9.03,1.505,13.768,1.505    c29.487,0,54.236-19.899,61.65-46.934C263.324,76.917,275.921,93.918,293.312,103.059z"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M225.965,158.121c-0.463,6.046-0.651,12.112-0.566,18.175c0.113,7.971,6.745,14.326,14.716,14.326h0    c8.257,0,14.753-6.799,14.666-15.055c-0.061-5.82,0.13-11.642,0.575-17.445"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="382.637" y1="84.38" x2="387.433" y2="84.38"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="411.729" y1="84.38" x2="416.526" y2="84.38"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="440.822" y1="84.38" x2="445.618" y2="84.38"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M364.157,129.145c-9.076,5.367-15.117,9.938-24.193,15.305c34.883,2.474,80.116,8.602,111.96-11.431    c39.334-24.745,32.214-72.725-1.141-95.327c-14.92-10.11-35.25-12.608-54.09-10.262    C339.964,34.494,333.799,102.826,364.157,129.145z"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="159.194" y1="323.204" x2="280.157" y2="323.204"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="159.194" y1="357.575" x2="213.548" y2="357.575"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M120.113,310.772l-1.036,59.688c-0.123,7.101-5.915,12.793-13.017,12.793H46.326c-7.19,0-13.018-5.828-13.019-13.018    l-0.006-59.424c-0.001-7.169,5.794-12.989,12.963-13.02l60.776-0.264C114.34,297.495,120.24,303.472,120.113,310.772z"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M90.698,331.115c0,7.736-6.253,13.99-13.99,13.99s-13.99-6.254-13.99-13.99c0-7.736,6.253-13.99,13.99-13.99    S90.698,323.379,90.698,331.115z"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M100.972,383.252H52.444c0-13.385,10.808-24.264,24.264-24.264S100.972,369.867,100.972,383.252z"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="357.736" y1="435.952" x2="478.699" y2="435.952"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="357.736" y1="470.322" x2="412.089" y2="470.322"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M318.655,423.519l-1.036,59.688c-0.123,7.101-5.915,12.793-13.017,12.793h-59.733c-7.19,0-13.018-5.828-13.019-13.018    l-0.006-59.424c-0.001-7.169,5.794-12.989,12.963-13.02l60.776-0.264C312.882,410.243,318.781,416.22,318.655,423.519z"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M289.239,443.863c0,7.736-6.253,13.99-13.99,13.99s-13.99-6.254-13.99-13.99c0-7.736,6.253-13.99,13.99-13.99    S289.239,436.126,289.239,443.863z"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M299.513,496h-48.527c0-13.385,10.808-24.264,24.264-24.264S299.513,482.615,299.513,496z"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M179.724,249.919c-0.039-9.322-0.078-18.644-0.117-27.966c-0.016-3.763-0.012-7.635,1.38-11.131    c1.392-3.496,4.524-6.573,8.285-6.731"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M201.622,199.701c1.288-0.644,2.575-1.288,3.863-1.931"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M278.84,193.892c4.4-1.875,9.395,0.923,12.835,4.246c8.102,7.824,12.538,19.297,11.807,30.537"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M285.428,208.715c4.406,0.475,7.859,5.187,6.985,9.531"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M217.789,261.146c20.611-1.546,41.221-3.092,61.832-4.638"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M263.017,246.328c2.577-0.507,5.153-1.014,7.73-1.521"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M399.673,125.618c18.414,3.897,38.702-2.909,51.04-17.123"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M458.194,100.087c1.635-1.469,3.271-2.939,4.906-4.408"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M372.921,60.185c9.981-13.617,28.241-20.602,44.762-17.123"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M393.235,58.187c4.016-3.83,9.648-5.907,15.189-5.602"/>
                                    </g>
                                </g>
                                <g id="Layer_1">
                                </g>
                            </svg>
                        </i>
                    </div>
                    <h5><strong><a>2. Find The Help You Need. <br> Or <br> Share What You Know.</a></strong></h5>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> -->
                    <!-- <a routerLink="/courses-1" class="link-btn">Start Now</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-lifestyle-box">
                    <div class="icon">
                        <i>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512; width:64px; height:64px" xml:space="preserve">
                                <g id="_x30_1_Creating_A_Profile">
                                    <g>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M76.155,106.483c2.474-2.503,3.833-6.071,3.65-9.586c-0.246-4.729-3.395-9.208-7.795-10.96c-4.373-1.742-9.726-0.667-13.089,2.626    c-3.362,3.294-4.546,8.624-2.895,13.032C59.033,109.617,70.024,112.688,76.155,106.483z"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M456.858,419.021c-0.578,20.674-17.657,37.111-38.331,37.111H54.41c-21.638,0-38.909-17.849-38.267-39.487    c2.633-92.843,2.889-185.815,0.963-278.786c68.252,0.771,136.504,1.092,204.756,1.156c78.717,0.064,157.371-0.321,235.96-2.247    C459.876,230.831,459.555,324.958,456.858,419.021z"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M493.648,419.021c-0.578,20.674-17.593,37.111-38.331,37.111h-36.79c20.674,0,37.753-16.437,38.331-37.111    c3.082-108.638,3.082-217.404-0.129-326.042c-0.578-20.674-17.593-37.111-38.331-37.111h36.855    c20.674,0,37.754,16.437,38.331,37.111C496.794,201.617,496.794,310.383,493.648,419.021z"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M457.821,136.769c-78.589,1.926-157.243,2.311-235.96,2.247c-68.252-0.064-136.504-0.385-204.756-1.156    c-0.321-14.125-0.706-28.315-1.091-42.505c-0.578-21.638,16.629-39.487,38.267-39.487h364.117    c20.739,0,37.754,16.437,38.331,37.111C457.179,107.555,457.5,122.194,457.821,136.769z"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M123.596,106.483c2.474-2.503,3.833-6.071,3.65-9.586c-0.246-4.729-3.395-9.208-7.795-10.96    c-4.373-1.742-9.727-0.667-13.089,2.626c-3.362,3.294-4.546,8.624-2.895,13.032C106.473,109.617,117.464,112.688,123.596,106.483z    "/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M171.036,106.483c2.474-2.503,3.833-6.071,3.65-9.586c-0.246-4.729-3.395-9.208-7.795-10.96    c-4.373-1.742-9.727-0.667-13.089,2.626s-4.546,8.624-2.895,13.032C153.913,109.617,164.904,112.688,171.036,106.483z"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M303.995,95.677c37.985,4.713,76.551,4.718,114.537,0.016"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M189.921,201.035l-1.376,79.264c-0.164,9.43-7.855,16.989-17.286,16.989H91.934c-9.548,0-17.288-7.739-17.289-17.287    l-0.009-78.914c-0.001-9.52,7.694-17.249,17.214-17.291l80.709-0.35C182.254,183.404,190.089,191.341,189.921,201.035z"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M150.858,228.051c0,10.273-8.304,18.578-18.578,18.578c-10.274,0-18.578-8.305-18.578-18.578c0-10.274,8.304-18.578,18.578-18.578    C142.553,209.473,150.858,217.777,150.858,228.051z"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M164.501,297.288h-64.443c0-17.775,14.352-32.222,32.222-32.222S164.501,279.514,164.501,297.288z"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M74.706,352.874c96.416,4.923,200.724,1.05,325.937-3.426"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M74.706,407.104c69.118,3.486,138.362,2.585,207.511,0.575"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M239.229,184.667c53.802,1.401,107.627,0.462,161.414-1.221"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M239.229,240.638c53.802,1.401,107.627,0.462,161.414-1.221"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:10.2128;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M239.229,296.608c53.802,1.401,107.627,0.462,161.414-1.221"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M37.762,229.798c-0.202,3.994-0.405,7.989-0.607,11.983"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M39.456,257.097c-0.499,48.14-0.997,96.281-1.496,144.421"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M340.784,430.159c19.075,0.506,38.15,1.012,57.224,1.518c8.898,0.236,18.7,0.168,25.445-5.639    c6.596-5.678,8.236-15.19,8.36-23.892c0.124-8.702-0.795-17.709,2.273-25.853"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M383.798,409.143c4.322,5.206,12.999,5.998,18.192,1.662c3.34-2.789,5.072-7.134,5.576-11.456s-0.091-8.688-0.685-12.999"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M200.523,119.7c34.837-0.425,69.674-0.85,104.511-1.274"/>
                                        
                                            <path style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="    M321.67,120.244c1.929,0,3.858,0,5.786,0"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="438.236" y1="55.886" x2="434.61" y2="59.512"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="459.836" y1="56.193" x2="447.223" y2="68.857"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="475.972" y1="61.963" x2="455.087" y2="82.899"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="487.053" y1="72.84" x2="456.976" y2="102.916"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="493.181" y1="88.567" x2="457.538" y2="124.21"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="494.049" y1="109.657" x2="457.998" y2="145.657"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="494.61" y1="131.001" x2="458.406" y2="167.154"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="494.968" y1="152.499" x2="458.713" y2="188.805"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.376" y1="174.048" x2="458.968" y2="210.457"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.632" y1="195.648" x2="459.07" y2="232.21"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.836" y1="217.35" x2="459.172" y2="254.014"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.938" y1="239.154" x2="459.121" y2="275.971"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.989" y1="261.01" x2="459.019" y2="297.98"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.887" y1="283.018" x2="458.815" y2="320.091"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.734" y1="305.078" x2="458.559" y2="342.252"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.53" y1="327.188" x2="458.151" y2="364.567"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="495.223" y1="349.401" x2="457.691" y2="386.933"/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="494.764" y1="371.767" x2="457.13" y2="409.401"/>
                                        
                                            <polyline style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="    494.253,394.184 452.074,436.363 437.215,451.223 432.313,456.125   "/>
                                        
                                            <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="493.691" y1="416.652" x2="454.219" y2="456.125"/>
                                        <g>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="125.827" y1="265.708" x2="100.704" y2="290.831"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="145.538" y1="267.903" x2="116.227" y2="297.265"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="157.793" y1="277.554" x2="138.083" y2="297.265"/>
                                            
                                                <line style="fill:none;stroke:#FA6E00;stroke-width:5.1064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="164.176" y1="293.078" x2="159.989" y2="297.265"/>
                                        </g>
                                    </g>
                                </g>
                                <g id="Layer_1">
                                </g>
                            </svg>
                        </i>
                    </div>
                    <h5><strong><a>3. Collaborate In Real Time.</a></strong></h5>
                    <h3>&nbsp;</h3>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> -->
                    <!-- <a routerLink="/courses-1" class="link-btn">Start Now</a> -->
                </div>
            </div>
        </div>

         <!-- <div class="single-lifestyle-box bottom-div">
            <h4 style="color: var(--mainColor); display: flex;align-items: center; justify-content: center;"><a routerLink="/profile-authentication" class="default-btn me-1" style="padding: 8px 16px;">FIND AN EXPERT:<span></span></a> Get the information you need, right at home. &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h4>
            <h4 style="margin-top: 0px; margin-bottom: 0px; color: var(--mainColor);"><strong>OR</strong></h4> 
            <h4 style=" color: var(--mainColor); display: flex;align-items: center; justify-content: center;"><a routerLink="/profile-authentication" class="default-btn me-1" style="padding: 8px 16px; background-color: var(--secondaryColor);">BE AN EXPERT:<span></span></a> Sign up to share your skills and earn some extra money.</h4>
        </div> -->


        <!-- <div class="banner-inner-area">
           
        </div> -->
    </div>
</div>
