import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpResError } from '../models/http-response-error.model';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorHandlerService {

  constructor() { }
  public handleHttpError(err: HttpErrorResponse) {
    // if (err.error instanceof ErrorEvent) {
    //   // Client Side or Network Error
    //   console.error('Client Side Error: ' , err.error.message);
    // } else {
    //   console.error('Server Side Error: ', err);
    // }
    const dataError = new HttpResError();
    dataError.status = err.status;
    dataError.friendlyMessage = 'An error occurred. Please try again later.';
    // dataError.error = (typeof err.error === 'object') ? JSON.stringify(err.error) : err.error;
    if (typeof err.error === 'object') {
      let errorString = '';
      for (const key in err.error) {
        if (err.error.hasOwnProperty(key)) {
          errorString += key + ' : ' + err.error[key];
        }
      }
      dataError.error = errorString;
    } else {
      dataError.error = err.error;
    }
    if (err.status === 400) {
      // return Observable.throw('400');
      dataError.message = 'Bad Request';
      if (err.error) {
        dataError.friendlyMessage = err.error;
      }
    } else if (err.status === 404) {
      // return Observable.throw('Not Found Error');
      dataError.message = 'Not Found Error';
      if (err.error) {
        dataError.friendlyMessage = err.error;
      }
    } else if (err.status === 423) {
      // return Observable.throw('423');
    } else if (err.status === 422) {
      // return Observable.throw(error);
    } else if (err.status === 401) {
      // return Observable.throw('Unauthorize');
      dataError.message = 'UnAuthorized';
    } else if (err.status === 0) {
      // return Observable.throw('Internet Connection Lost');
      dataError.message = 'Internet Connection Lost';
    } else if (err.status === 500) {
      // return Observable.throw('Internal Server Error');
      dataError.message = 'Internal Server Error';
    }
    // return Observable.throw('App Error ' + dataError);
    // return ErrorObservable.create(dataError);
    return throwError(dataError);
  }
}
