import { Injectable } from '@angular/core';


declare var gtag : any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  trackEvent(eventName: string, eventDetails: string, eventCategory: string) {
    gtag('event', eventName, {
      // Event Type - example: 'SCROLL_TO_TOP_CLICKED'
      'event_category': eventCategory,
      // The label that will show up in the dashboard as the event's name
      'event_label': eventName,
      // A short description of what happened
      'value': eventDetails
    });
  }
}
