<app-ct-navbar></app-ct-navbar>
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>Terms and Condition</li>
      </ul>
      <!-- <h2>Terms of Service</h2> -->
    </div>
  </div>
  <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div> -->
</div>

<div class="terms-of-service-area pb-100">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="bg-primary" style="height: calc(100vh - 100px);">
            <app-pdf-view [documentUrl]="documentUrl"></app-pdf-view>
          </div>
          
      </div>
    </div>
  </div>
</div>
<app-ct-footer></app-ct-footer>
