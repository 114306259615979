<app-ct-navbar></app-ct-navbar>
<app-ct-banner></app-ct-banner>
<app-ct-experience></app-ct-experience>
<app-ct-be-an-expert></app-ct-be-an-expert>
<!-- <div class="advisor-area bg-f9f9f9 pt-100">
    <app-ct-works></app-ct-works>
</div> -->
<app-ct-how-it-works></app-ct-how-it-works>
<app-ct-app-download id="app-download" #appDownload></app-ct-app-download>
<!-- <app-ct-trust-fun-safty></app-ct-trust-fun-safty> -->
<app-ct-footer></app-ct-footer>