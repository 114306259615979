import { Component, ElementRef, OnInit, Renderer2, ViewChild } from "@angular/core";

@Component({
  selector: "app-ct-experience",
  templateUrl: "./ct-experience.component.html",
  styleUrls: ["./ct-experience.component.scss"],
})
export class CtExperienceComponent implements OnInit {
  @ViewChild("appDownload", { static: true }) appDownload: ElementRef;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  scrollToDownload() {
    const element = this.renderer.selectRootElement("#app-download", true);
    element.scrollIntoView({ behavior: "smooth" });
  }
}
