import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-ct-trust-fun-safty',
  templateUrl: './ct-trust-fun-safty.component.html',
  styleUrls: ['./ct-trust-fun-safty.component.scss']
})
export class CtTrustFunSaftyComponent implements OnInit {
  constructor( public router: Router) { }

ngOnInit(): void {}

instructorFeedbackSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='bx bx-chevron-left'></i>",
        "<i class='bx bx-chevron-right'></i>"
    ],
    responsive: {
        0: {
            items: 1
        },
        576: {
            items: 1
        },
        768: {
            items: 2
        },
        1200: {
            items: 2
        }
    }
}

}
