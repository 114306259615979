import { Component } from '@angular/core';

@Component({
  selector: 'app-ct-how-it-works-detail',
  templateUrl: './ct-how-it-works-detail.component.html',
  styleUrls: ['./ct-how-it-works-detail.component.scss']
})
export class CtHowItWorksDetailComponent {

}
