import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { HttpResError } from "src/app/models/http-response-error.model";
import { SupportDto } from "src/app/models/support.model";
import { SupportService } from "src/app/services/support.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-ct-contact-us",
  templateUrl: "./ct-contact-us.component.html",
  styleUrls: ["./ct-contact-us.component.scss"],
})
export class CtContactUsComponent implements OnInit {
  baseUrl = environment.baseUrl;
  submitStatus: string | undefined;
  supportDto = new SupportDto();
  constructor(
    private router: Router,
    private supportService: SupportService,
    private http: HttpClient,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}
  onSubmit() {
    const url =
      "http://canitudeapi-test.us-east-1.elasticbeanstalk.com/api/ContactUs";

    this.http.post(url, this.supportDto).subscribe(
      (response: any) => {
        console.log("Response:", response);

        if (response.success) {
          this.submitStatus = "Message sent successfully!";
        } else {
          this.submitStatus =
            "Failed to send the message. Please try again later.";
        }
      },
      (error) => {
        console.error("Error sending message:", error);
        this.submitStatus =
          "Failed to send the message. Please try again later.";
      }
    );
  }
  contactUs() {
    this.supportService.contectUs(this.supportDto).subscribe({
        next: (res: any) => {
            console.log(res);
            this.toastr.success("Message submitted successfully.");
            this.supportDto = new SupportDto();
        },
        error: (error: HttpResError) => {
          this.toastr.warning("Message is not submitted");
            this.toastr.error(error.error);
        },
    });
}


  bgImage = [
    {
      img: "../../../../assets/img/DarkOrange.jpeg",
    },
  ];

  submit(form) {
    var name = form.name;
    console.log(name);

    var email = form.email;
    console.log(email);

    var number = form.number;
    console.log(number);

    var message = form.message;
    console.log(message);
  }
}
