import { Component, Input, AfterViewInit, ViewChild, ElementRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: "app-pdf-view",
  templateUrl: "./pdf-view.component.html",
  styleUrls: ["./pdf-view.component.scss"],
})
export class PdfViewComponent implements OnInit {
  @Input() documentUrl = '';
  // @Input() documentUrl = '../../../../assets/docs/Term&Condition.pdf';
  pdfDocumentView: SafeResourceUrl;
  constructor(private sanatizer: DomSanitizer, private cdr: ChangeDetectorRef) {

   }

  ngOnInit() {
    if (this.documentUrl) {
      this.pdfDocumentView = this.sanatizer.bypassSecurityTrustResourceUrl(this.documentUrl);
      setTimeout(() => {
        this.cdr.detectChanges();
      }, 1000);
    }
  }
 
}
