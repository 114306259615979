<div class="container">
    <div class="section-title">
        <!-- <span class="sub-title">Course Advisor</span> -->
        <h2>How CANITUDE Works</h2>
        <p><strong style="color: var(--mainColor);">FIND AN EXPERT:</strong> Get the information you need, right at home. &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p style="margin-top: 0px; margin-bottom: 0px;"><strong>OR</strong></p> 
        <p><strong  style="color: var(--mainColor);">BE AN EXPERT:</strong> Sign up to share your skills and earn some extra money.</p>
    </div>
    <div class="advisor-slides-two">
        <owl-carousel-o [options]="advisorSlidesTwo">
            <ng-template carouselSlide>
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img src="../../../../assets/img/01-Creating-A-Profile (1) (1).png" alt="image">
                        <!-- <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul> -->
                    </div>
                    <div class="advisor-content">
                        <p><a routerLink="">1. CREATE A Profile</a></p>
                        <!-- <span>Project Management Expert</span> -->
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img src="../../../../assets/img/02-Search-And-Chat-With-A-Teacher (1).png" alt="image">
                        <!-- <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul> -->
                    </div>
                    <div class="advisor-content">
                        <p><a routerLink="">2. FIND THE HELP YOU NEED. or SHARE WHAT YOU KNOW.</a></p>
                        <!-- <span>Illustrator Expert</span> -->
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img src="../../../../assets/img/03-Start-The-Online-Session (1).png" alt="image">
                        <!-- <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul> -->
                    </div>
                    <div class="advisor-content">
                        <p><a routerLink="">3. COLLABORATE in real time.</a></p>
                        <!-- <span>QA Project Expert</span> -->
                    </div>
                </div>
            </ng-template>
            <!-- <ng-template carouselSlide>
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img src="assets/img/advisor/img7.jpg" alt="image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="advisor-content">
                        <h3><a routerLink="/profile">Lina Ninja</a></h3>
                        <span>QA Project Expert</span>
                    </div>
                </div>
            </ng-template> -->
        </owl-carousel-o>
    </div>
    <!-- <div class="section-title">
        <span class="sub-title">Course Advisor</span>
        <h2>How CANITUDE Works</h2>
        <p><strong style="color: var(--mainColor);">FIND AN EXPERT:</strong> Get the information you need, right at home. &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p style="margin-top: 0px; margin-bottom: 0px;"><strong>OR</strong></p> 
        <p><strong  style="color: var(--mainColor);">BE AN EXPERT:</strong> Sign up to share your skills and earn some extra money.</p>
    </div> -->
</div>