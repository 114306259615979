
<div class="oa-blog-area bg-f5f7fa  pb-70"><!--pt-100-->
    <div class="container">
        <div class="section-title-3" style="background-color: var(--secondaryColor); border-radius: 5px;">
            <p style="color: var(--whiteColor); margin-top: 0px; margin-bottom: 0px; text-align: center; padding-bottom: 0px;"><strong> BE AN EXPERT:</strong></p>
            <p style="color: var(--whiteColor);  margin-top: 0px; margin-bottom: 0px; padding-top: 0px;">Offer to <a  (click)="scrollToDownload()" style="text-decoration: underline; cursor: pointer;">share your knowledge</a> to help someone else. Earn money for your expertise.</p>
            <!-- <h2>BE AN EXPERT</h2>
            <p>Offer to <a style="text-decoration: underline; color: var(--secondaryColor);">share your knowledge</a> to help someone else. Earn money for your expertise.</p> -->
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="oa-blog-card">
                    <div class="blog-image">
                        <a>
                            <img src="../../../../assets/img/modern-automobile-mechanic-composition.jpg" alt="image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <h3><a>Sarah needs to put in a new faucet.</a></h3>
                        <p><strong>Sam, in Dubuque, is a plumber who can share his knowledge and make a little bit of money on his weekend.</strong></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="oa-blog-card">
                    <div class="blog-image">
                        <a>
                            <img src="../../../../assets/img/woman-working-hard-greenhouse.jpg" alt="image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <h3><a>Bill’s worried about his hydrangeas.</a></h3>
                        <p><strong>Sandy is a master gardener. She’ll tell him when to prune.</strong></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="oa-blog-card">
                    <div class="blog-image">
                        <a>
                            <img src="../../../../assets/img/work-from-home-freelance-lifestyle-pandemic-lockdown-new-normal.jpg" alt="image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <h3><a>Tom wants to surprise his wife with… what?</a></h3>
                        <p><strong>Mary is an event planner who can give him some ideas that’ll warm his wife’s heart.</strong></p>
                    </div>
                </div>
            </div>
        </div>


        <div class="single-training-box bottom-box" style="padding-top: 32px;">
            <h3> <a style="color: var(--secondaryColor);">SHARE YOUR SKILL – on YOUR time.</a></h3>
            <p><strong>What you know can earn you some dough. Be a CANITUDE expert.<br>Solve others’ problems and earn a bit on the side.</strong> </p>
        </div>
    </div>
</div>


<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Courses List 01</li>
            </ul>
            <h2>Courses List 01</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div> -->

