import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { HttpErrorHandlerService } from "./http-error-handler.service";
import { SupportDto } from "../models/support.model";
import { catchError } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class SupportService {
  baseUrl = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private httpErrorService: HttpErrorHandlerService,
    private router: Router
  ) {}

  contectUs(data: SupportDto) {
    return this.http
      .post(`${this.baseUrl}ContactUs`, data, httpOptions)
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
}
