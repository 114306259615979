<div class="about-area-two pb-100 pt-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <!-- <span class="sub-title">Distance Learning</span>  href="#app-download"-->
                    <h2>You can do it. We can help. All you need is a CAN-DO attitude.</h2>
                    <div class="option-item">
                        <a routerLinkActive="active" routerLink="/support" class="default-btn"><i class="flaticon-user"></i>MEET CANITUDE<span></span></a>
                    </div>
                    <h3 class="mt-5"><strong>In a one-on-one video call,<br> get (or give)<br> the help that’s needed.</strong></h3>
                    <!-- <p><strong>In a one-on-one video call with a skill giver,get the specific info you need.</strong></p> -->
                    <!-- <a routerLink="/courses-1" class="link-btn">Explore Learning</a> -->
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-video-box">
                    <a class="d-block">
                        <div class="image">
                            <img src="../../../../assets/img/You Can.jpg" alt="image">
                        </div>
                        <!-- <div class="video-btn">
                            <i class="flaticon-play"></i>
                        </div> -->
                    </a>
                    <!-- <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                       
                    </lightgallery> -->
                    <div class="shape10"><img *ngFor="let x of [1,2,3]" style="margin-right: 6px;" src="assets/img/shape9.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div> -->
    <!-- <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div> -->
    <!-- <div class="shape2"><img src="assets/img/shape2.png" alt="image"></div> -->
</div>

<!-- <app-funfacts-style-one></app-funfacts-style-one>

<div class="testimonials-area ptb-100">
    <app-testimonials></app-testimonials>
</div>

<div class="bg-f9f9f9 pb-100">
    <app-instructor></app-instructor>
</div>

<div class="partner-area ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div> -->