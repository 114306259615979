<app-ct-navbar></app-ct-navbar>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>How It Works</li>
            </ul>
            <!-- <h2>How CANITUDE Works</h2> -->
        </div>
    </div>
    <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div> -->
</div>
<div class="terms-of-service-area pt-70 pb-100">
    <div class="container">
        <div class="section-title">
            <h2 >How CANITUDE Works</h2>
        <!-- <p><strong style="color: var(--mainColor);">FIND AN EXPERT:</strong> Get the information you need, right at home. &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p style="margin-top: 0px; margin-bottom: 0px;"><strong>OR</strong></p> 
        <p><strong  style="color: var(--mainColor);">BE AN EXPERT:</strong> Sign up to share your skills and earn some extra money.</p> -->
            <!-- <h2 class="playfair-display-font">Inspring you to live a healthier lifestyle</h2> -->
            <p>CANitude is a platform that enables you to develop the "CAN-do" mentality by connecting you with a person that has the skill that you want to develop. You CAN do it yourself – if you have an Expert’s advice.</p>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="terms-of-service-content">
                    <!-- <img src="assets/img/courses/img2.jpg" alt="image">
                    <p><i>This Terms of Service was last updated on January 1, 2020.</i></p> -->
                    <h3>Step one: Create a profile</h3>
                    <!-- <p>Our website address is: http://odemy.com</p> -->
                    <blockquote class="blockquote">
                        <p>You can sign-up for a user account for free by downloading our mobile App or by visiting our website at www.canitude.com. 
                            During sign-up for your user account, you will be required to provide us with your full name, email address, mobile phone number, 
                            date of birth, location (country), and profile image.
                        </p>
                        <p>
                            Depending on whether you sign up for an account as a Learner or as an Expert, we may request some additional information from you.
                        </p>
                        <p>
                            As a Learner, you can connect to people that will show you that you CAN do it yourself without someone doing it for you. 
                            You will pay for their services with a credit card or via PayPal.
                        </p>
                        <p>
                            As an Expert, you will get access to connect to people who simply need your guidance to do the things they need to do. 
                            Get paid for your expertise when you provide the advice.
                        </p>
                    </blockquote>
                    <h3>Step two: 
                        FIND THE HELP YOU NEED
                        or
                        SHARE WHAT YOU KNOW
                        </h3>
                        <blockquote class="blockquote">
                            <p>As a Learner, you can search for the skill you want to develop or the task with which you need help. We will provide our recommendation for 
                                the available Expert who can coach you to develop the skill or to complete the project yourself. Experts and Learners don't have to be within 
                                the same geographic area. You’ll build confidence by completing your own projects and learning new skills.
                            </p>
                            <p>
                                As an Expert, you can share your expertise with people who needs it. Your profile will appear in their search for the skill they want to develop. 
                                You can post your specific skill to be noticed by others who may be looking for it. You will connect to learners interested in booking a session with you. 
                                You won’t have to travel to offer your services. You can make money by sharing your skills even while you are home or on vacation.
                            </p>
                        </blockquote>
                    <h3>Step three: Step one: Create a profile</h3>
                    <blockquote class="blockquote"> 
                        <p>Schedule a time slot in the near future or have an instant videocall with your selected Expert. Develop the skill you want. 
                            Get the help you need to do it yourself. Get real-time coaching and feedback from a skilled partner.
                        </p>
                    </blockquote>
                    <h3>Payments</h3>
                    <blockquote class="blockquote">
                        <p>
                            There’s no need to save your credit card information. You can pay with CANitude credits. All payments on the platform are processed by 
                            a secure third-party payment processor.
                        </p>
                    </blockquote> 
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_insight">
                        <ul>
                            <li><a routerLink="/about-1">About Us</a></li>
                            <li><a routerLink="/contact">Contact Us</a></li>
                            <li><a routerLink="/purchase-guide">Purchase Guide</a></li>
                            <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                            <li class="active"><a routerLink="/terms-of-service">Terms of Service</a></li>
                        </ul>
                    </section>
                    <section class="widget widget_recent_courses">
                        <h3 class="widget-title">Recent Courses</h3>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info">
                                <span>$49.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">The Data Science Course 2020: Complete Data Science Bootcamp</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info">
                                <span>$59.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">Java Programming Masterclass for Software Developers</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info">
                                <span>$69.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On Artificial Neural Networks</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </section>
                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Popular Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Braike <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                </aside>
            </div> -->
        </div>
    </div>
</div>
<app-ct-footer></app-ct-footer>