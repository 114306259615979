import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ct-be-an-expert',
  templateUrl: './ct-be-an-expert.component.html',
  styleUrls: ['./ct-be-an-expert.component.scss']
})
export class CtBeAnExpertComponent implements OnInit {
  @ViewChild("appDownload", { static: true }) appDownload: ElementRef;
  

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  scrollToDownload() {
    const element = this.renderer.selectRootElement('#app-download', true);
    element.scrollIntoView({ behavior: 'smooth' });
  }

}
