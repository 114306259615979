<app-ct-navbar></app-ct-navbar>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>YOU CAN</li>
            </ul>
            <h2>YOU CAN</h2>
        </div>
    </div>
</div>
    <div class="about-area bg-f5f7fa ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-image text-center">
                        <img src="../../../../assets/img/ct-main-banner3.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <!-- <span class="sub-title">About Us</span> -->
                        <p><strong>CANitude has been developed to close the gap between you and the personalized information you need to get the job done.</strong></p>
                        <p>The CANitude crew of Experts stands ready to help you, in real time, get your questions answered.</p>
                        <ul class="features-list">
                            <li><span><i class="flaticon-experience"></i> Skiled Experts</span></li>
                            <li><span><i class="flaticon-time-left"></i> Afordable Rates</span></li>
                            <li><span><i class="flaticon-tutorials"></i> Efficient & Flexible</span></li>
                            <li><span><i class="flaticon-self-growth"></i> Realtime Access</span></li>
                        </ul>
                        <a (click)="scrollAndNavigate()" class="default-btn"><i class="flaticon-user"></i>Join Us<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-ct-footer></app-ct-footer>
