import { Component, OnInit } from '@angular/core';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import lgVideo from 'lightgallery/plugins/video';

@Component({
  selector: 'app-ct-banner',
  templateUrl: './ct-banner.component.html',
  styleUrls: ['./ct-banner.component.scss']
})
export class CtBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {}

  settings = {
      counter: false,
      plugins: [lgVideo]
  };
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
      const { index, prevIndex } = detail;
      console.log(index, prevIndex);
  };
}
