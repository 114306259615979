import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ct-trust-and-fun',
  templateUrl: './ct-trust-and-fun.component.html',
  styleUrls: ['./ct-trust-and-fun.component.scss']
})
export class CtTrustAndFunComponent implements OnInit {
  constructor(private router: Router) {}
  ngOnInit(): void {}

  scrollAndNavigate() {
    this.router.navigate(["/home"], { queryParams: { id: "app-download" } });
  }
}