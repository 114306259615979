<app-ct-navbar></app-ct-navbar>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>TRUST AND FUN.</li>
            </ul>
            <h2>TRUST AND FUN.</h2>
        </div>
    </div>
    <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div> -->
</div>
<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image text-center">
                    <img src="../../../../assets/img/about-img12.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">About Us</span> -->
                    <!-- <h2><a href="">TRUST AND FUN.</a></h2> -->
                    <p>
                     <strong>
                        Everyone’s an expert about SOMETHING. You may have just the information that someone else is needing.
                        Or you may need information and be embarrassed to ask a friend.
                     </strong>
                   </p>
                    <p><strong>CANITUDE gives you a way to learn from a trusted Expert… or to share your knowledge with a stranger in need.</strong></p>
                    <a (click)="scrollAndNavigate()" class="default-btn mt-2"><i class="flaticon-user"></i>Join Us<span></span></a>
                </div>
            </div>
        </div>
    </div>
</div>
<app-ct-footer></app-ct-footer>