<app-ct-navbar></app-ct-navbar>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Safety</li>
            </ul>
            <h2>Safety</h2>
        </div>
    </div>
    <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div> -->
</div>
<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image text-center">
                    <img src="../../../../assets/img/security.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">About Us</span> -->
                    <!-- <h2><a href="">Safety</a></h2> -->
                    <p>
                     <strong>
                        One of the strengths of CANITUDE is that you can chat with your Expert whenever/wherever you need to: 
                        in the moment of the emergency, in the garage with the tire that needs fixing, in the kitchen as you 
                        learn to impress that new date with your cooking. Your Expert can help!
                     </strong>
                    </p>
                    <a (click)="scrollAndNavigate()" class="default-btn mt-2"><i class="flaticon-user"></i>Join Us<span></span></a>
                </div>
            </div>
        </div>
    </div>
</div>
<app-ct-footer></app-ct-footer>