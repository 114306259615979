<app-ct-navbar></app-ct-navbar>
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>About Us</li>
      </ul>
      <h2>About Us</h2>
    </div>
  </div>
  <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div> -->
</div>

<div class="about-area-three ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-content-box">
          <span class="sub-title">About The CANITUDE</span>
          <!-- <h2>About The CANITUDE</h2> -->
          <!-- <p>Want to learn and earn PDUs or CEUs on your schedule — anytime, anywhere? Or, pick up a new skill quickly like, project team leadership or agile? Browse our most popular online courses.</p> -->
          <p>
            <strong>
                The name CANitude is clearly a mashup of the words CAN and
                ATTITUDE. People who have CANitude have that famous “CAN-DO
                Attitude.”
            </strong>
          </p>
          <p>
            CANitude was developed to encourage people to believe in themselves
            and to have that CAN-DO attitude. We have all hired a professional
            when we probably could have done the job ourselves, but we lacked
            confidence in our ability. CANitude closes this gap. You can connect
            with a CANitude Expert who will help you to do your project yourself
            – while the Expert is making a little bit of money and doesn’t have
            to be physically present as they consult with you. It’s a win-win
            situation for both parties.
          </p>
          <p>
            CANitude is a unique online marketplace where Learners learn from
            Experts’ advice and knowledge through live sessions together. The
            Platform serves as a neutral venue for users to offer and acquire
            Expert Services.
          </p>
          <p>
            We believe that the mix of a CAN-DO attitude and direct coaching
            from an Expert is the secret to completing a successful DYI project.
          </p>
          <p>
            Watching online videos is a great tool to help us in many cases, but
            many times, it’s not quite what we need. Online forums, on the other
            hand allow us to ask the specific question. But often, when we get
            the answer to our question, it’s not clear or it’s not enough, and
            we need further assistance. CANitude offers the change to videochat
            with an Expert and get our needs specifically addressed.
          </p>
          <!-- <a routerLink="/courses-1" class="default-btn"><i class="flaticon-user"></i>View All Courses<span></span></a> -->
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="about-img">
          <div class="image">
            <img src="../../../../assets/img/about-img77.png" alt="image" />
            <img src="../../../../assets/img/about-img88.png" alt="image" />
          </div>
          <div class="shape17"><img src="assets/img/shape16.png" alt="image" /></div>
          <div class="shape18"><img src="assets/img/shape17.png" alt="image" /></div>
          <div class="shape19"><img src="assets/img/shape18.png" alt="image" /></div>
          <div class="shape20"><img src="assets/img/shape19.png" alt="image" /></div>
          <div class="shape21"><img src="assets/img/shape20.png" alt="image" /></div>
          <div class="shape22"><img src="assets/img/shape21.png" alt="image" /></div>
          <div class="shape23"> <img src="assets/img/shape22.png" alt="image" /></div>
        </div>
      </div>
    </div>
  </div>
  <div class="shape3"><img src="assets/img/shape3.png" alt="image" /></div>
  <div class="shape4"><img src="assets/img/shape4.png" alt="image" /></div>
</div>

<!-- <app-funfacts-style-two></app-funfacts-style-two> -->
<app-ct-footer></app-ct-footer>
<!-- <div class="partner-area pt-70 pb-100">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div> -->

<!-- <app-feedback></app-feedback> -->

<!-- <app-instructor></app-instructor> -->

<!-- <div class="premium-access-area bg-f9f9f9 ptb-100">
    <app-premium-access></app-premium-access>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
</div> -->
