import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-navbar',
  templateUrl: './ct-navbar.component.html',
  styleUrls: ['./ct-navbar.component.scss']
})
export class CtNavbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  classApplied = false;
  toggleClass() {
      this.classApplied = !this.classApplied;
  }
}
