import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-si-courses',
  templateUrl: './si-courses.component.html',
  styleUrls: ['./si-courses.component.scss']
})
export class SiCoursesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
