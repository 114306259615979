import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-ct-home",
  templateUrl: "./ct-home.component.html",
  styleUrls: ["./ct-home.component.scss"],
})
export class CtHomeComponent implements OnInit {
  @ViewChild("appDownload", { static: false }) appDownload: ElementRef;

  constructor(private route: ActivatedRoute, private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((params) => {
      const scrollTo = params["id"];
      if (scrollTo === "app-download") {
        setTimeout(() => {
          this.scrollToElement();
        }, 500);
      }
    });
  }

  private scrollToElement(): void {
    const element = this.renderer.selectRootElement("#app-download", true);
    element.scrollIntoView({ behavior: "smooth" });
  }
}
