<div class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="overview-content">
                <span class="sub-title">Distance Learning</span>
                <h2>Feel Like You Are Attending Your Classes Physically!</h2>
                <p>Odemy training programs can bring you a super exciting experience of learning through online! You never face any negative experience while enjoying your classes virtually by sitting in your comfort zone. Our flexible learning initiatives will help you to learn better and quicker than the traditional ways of learning.</p>
                <a routerLink="/contact" class="default-btn"><i class="flaticon-user"></i>Get Started Now<span></span></a>
            </div>
            <div class="overview-image">
                <img src="assets/img/overview-img1.png" alt="image">
            </div>
        </div>
        <div class="overview-box">
            <div class="overview-image">
                <img src="assets/img/overview-img2.png" alt="image">
            </div>
            <div class="overview-content">
                <span class="sub-title">Odemy Mobile App</span>
                <h2>Access From Your Mobile, Learn Any Time Any Where</h2>
                <p>Odemy training programs can bring you a super exciting experience of learning through online! You never face any negative experience while enjoying your classes virtually by sitting in your comfort zone. Our flexible learning initiatives will help you to learn better and quicker than the traditional ways of learning.</p>
                <div class="btn-box">
                    <a href="#" class="playstore-btn">
                        <img src="assets/img/playstore.png" alt="image">
                        GET IT ON
                        <span>Google Play</span>
                    </a>
                    <a href="#" class="applestore-btn">
                        <img src="assets/img/applestore.png" alt="image">
                        GET IT ON
                        <span>Apple Store</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>