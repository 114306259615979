<app-ct-navbar></app-ct-navbar>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>FAQ's</li>
            </ul>
            <h2>Frequently Asked Questions</h2>
        </div>
    </div>
    <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div> -->
</div>

<div class="faq-area ptb-100">
    <div class="container">
        <div class="faq-accordion">
            <accordion>
                <accordion-group heading="What is CANitude?">
                    <p>CANitude is a service that helps people to do things they like/want to do themselves. The main goal is to develop a CAN-do mentality and attitude. Everyone can do what they need to get done, with an expert to answer questions and with the attitude to learn.</p>
                    <!-- <p><strong>1.</strong> Sony PlayStation 4 Pro – Best Overall<br>
                        <strong>2.</strong> Xbox One S – Best Gaming Console for Multimedia<br>
                        <strong>3.</strong> Nintendo Switch – Best Hybrid Gaming Console<br>
                        <strong>4.</strong> Nintendo Switch Lite – Best for Portable Play
                    </p> -->
                </accordion-group>
                <accordion-group heading="Do Experts and Learners sign-up for different accounts on the platform (Learner and Expert account) to access specific features such as listing services, accepting orders, placing orders, etc., or can the same user account be used for both buying and selling services?">
                    <p>Experts and Learners should sign-up for different accounts. When you sign up, you must decide whether to be an Expert or a Learner. (You can sign up as both – it just requires two sign-ups.)</p>
                    <!-- <ul>
                        <li>a console</li>
                        <li>Two Joy-Con controllers that are detachable</li>
                        <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                        <li>Two straps for turning the Joy-Cons into individual controllers</li>
                        <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                    </ul> -->
                </accordion-group>
                <accordion-group heading="How do I find an Expert and get started?">
                    <p>CANitude will help you search for the Expert who can help you develop the skills that you want. We will recommend an Expert based on your search criteria, but feel free to browse through our Experts’ profiles and find the one that best suits you.</p>
                </accordion-group>
                <accordion-group heading="Can I refer others to CANitude?">
                    <p>Of course, you can, and we'd love it if you would do that. Everyone is welcome to take advantage of the learning and/or helping opportunities offered by CANitude.</p>
                    <!-- <ul>
                        <li>a console</li>
                        <li>Two Joy-Con controllers that are detachable</li>
                        <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                        <li>Two straps for turning the Joy-Cons into individual controllers</li>
                        <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                    </ul> -->
                </accordion-group>
                <accordion-group heading="How much does it cost to book a session?">
                    <p>Experts post their hourly rates on their profiles. You have the option to book a 15 minute, half hour, or 1 hour session with your Expert. You also have the option to go into an instantaneous session or to book future sessions with a fixed date and time. If your session with your Expert ends before you’re ready to end it, you can extend it to another time slot.</p>
                </accordion-group>
                <accordion-group heading="Can I contact an Expert before booking a session?">
                    <p>Yes, you can text the Experts before booking a session.</p>
                    <!-- <ul>
                        <li>a console</li>
                        <li>Two Joy-Con controllers that are detachable</li>
                        <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                        <li>Two straps for turning the Joy-Cons into individual controllers</li>
                        <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                    </ul> -->
                </accordion-group>
                <accordion-group heading="How do I pay the Expert?">
                    <p>Learners can purchase and use CANitude credits to pay for their selected Expert Services. All payments made on the CANitude platform are processed by a secure third-party payment processor.</p>
                </accordion-group>
                <accordion-group heading="How can I reset my password?">
                    <p>You can do that on the Settings page. You can <a style="text-decoration: underline; color: var(--secondaryColor);">click here</a> to reset your password.</p>
                </accordion-group>
                <accordion-group heading="As an Expert, how do I set my availability?">
                    <p>You can set your availability through the profile page. </p>
                </accordion-group>
                <accordion-group heading="Are there any fees to become an Expert?">
                    <p>Becoming an Expert is free; there are no fees. However, the Expert will pay a percentage of the Learner’s payment to CANitude out of each session.</p>
                </accordion-group>
                <accordion-group heading="How do I fix an error while processing a payment?">
                    <p>Submit a help request to customer support at <a style="text-decoration: underline; color: var(--secondaryColor);">info&#64;canitude.com.</a> </p>
                </accordion-group>
                <accordion-group heading="What are the different Expert Levels that you offer? Can my Expert level be upgraded or downgraded?">
                    <p>There are two Expert levels: all Experts will begin at the ordinary/regular level. To upgrade, the Expert needs to achieve certain requirements. If the Expert fails to commit to any of the upgraded profile conditions, the Professional standing will be downgraded.</p>
                </accordion-group>
                <accordion-group heading="Do you send a verification email or an SMS during account registration?">
                    <p>A verification email will be sent to you (via a push notification) about your sessions through the app or via email or text. The user will configure notifications through the app.</p>
                </accordion-group>
                <accordion-group heading="Can Learners rate and review an Expert after the project/order is complete?">
                    <p>Yes.  Learners will be able to rate and review an Expert after their project is complete. However, the Learner will not be able to leave a review for an Expert if the Learner does not attend a session with that Expert.</p>
                </accordion-group>
            </accordion>
        </div>
    </div>
</div>

<!-- <div class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape12.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape13.png" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape14.png" alt="image"></div>
</div> -->
<app-ct-footer></app-ct-footer>