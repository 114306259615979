<div class="about-area-three ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title">Distance Learning</span>
                    <h2>Build Your Skills Online, Anytime</h2>
                    <p>Want to learn and earn PDUs or CEUs on your schedule — anytime, anywhere? Or, pick up a new skill quickly like, project team leadership or agile? Browse our most popular online courses.</p>
                    <p><strong>Grow your knowledge and your opportunities with thought leadership, training and tools.</strong></p>
                    <a routerLink="/courses-4" class="default-btn"><i class="flaticon-user"></i>View All Courses<span></span></a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-img">
                    <div class="image">
                        <img src="assets/img/about-img7.png" alt="image">
                        <img src="assets/img/about-img8.png" alt="image">
                    </div>
                    <div class="shape17"><img src="assets/img/shape16.png" alt="image"></div>
                    <div class="shape18"><img src="assets/img/shape17.png" alt="image"></div>
                    <div class="shape19"><img src="assets/img/shape18.png" alt="image"></div>
                    <div class="shape20"><img src="assets/img/shape19.png" alt="image"></div>
                    <div class="shape21"><img src="assets/img/shape20.png" alt="image"></div>
                    <div class="shape22"><img src="assets/img/shape21.png" alt="image"></div>
                    <div class="shape23"><img src="assets/img/shape22.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
</div>