import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AnalyticsService } from "src/app/analytics.service";

@Component({
  selector: "app-ct-footer",
  templateUrl: "./ct-footer.component.html",
  styleUrls: ["./ct-footer.component.scss"],
})
export class CtFooterComponent implements OnInit {
  constructor(private router: Router, private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    
    // Example of tracking an event when the footer loads
    this.analyticsService.trackEvent(
      'FOOTER_LOADED',
      'Footer component loaded successfully',
      'User Interaction'
    );
  }

  scrollAndNavigate(route: string) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.router.navigate([route]);
  }





  shareLink() {
    if (navigator.share) {
      navigator
        .share({
          title: "Title of the shared link",
          text: "Description of the shared link",
          url: "https://canitude.com",
        })
        .then(() => console.log("Link shared successfully"))
        .catch((error: any) => console.error("Error sharing link:", error));
    } else {
      console.log("Web Share API not supported");
    }
  }
}
