<div class="training-area bg-f5f7fa  pb-70 pt-70">
    <div class="container">
        <div class="section-title-2">
            <h2 class="" style="margin-bottom: 70px;">Learn About The CANITUDE Experience. It’s Unique.</h2>
            <div style="background-color: var(--mainColor); color: var(--whiteColor); border-radius: 5px;  max-width: 542px; text-align: center; margin: 0 auto;">
                <p style="color: var(--whiteColor);  margin-top: 0px; margin-bottom: 0px; text-align: center; padding-bottom: 0px;"><strong>FIND AN EXPERT:</strong></p>
                <p style="color: var(--whiteColor); margin-top: 0px; margin-bottom: 0px; padding-top: 0px;">Reach out, in a video call, to <a  (click)="scrollToDownload()" style="text-decoration: underline; cursor: pointer;">get the help</a> you need.</p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-training-box">
                    <img src="../../../../assets/img/How do I unclog my kitchen sink.jpg"  alt="image">
                    <h3><a>How do I unclog my kitchen sink?</a></h3>
                    <p><strong>Ask an Expert. They’ll walk you through the process.</strong></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-training-box">
                    <img src="../../../../assets/img/How can I impress my new mother in law.jpg" alt="image">
                    <h3><a>How can I impress my new mother-in-law?</a></h3>
                    <p><strong>From cooking ideas to decorating ideas, Your Expert will share the latest tips and tricks.</strong></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-training-box">
                    <img src="../../../../assets/img/How should I design living space.jpg" alt="image">
                    <h3><a>How should I design my living space?</a></h3>
                    <p><strong>We’ve got just the advice you need.Show your Expert your room and learn how.</strong></p>
                </div>
            </div>
        </div>
        <div class="single-training-box bottom-box">
            <h3><a style="color: var(--mainColor);">EVERY SKILL YOU NEED. All in one place.</a></h3>
            <p><strong>From decorating and cooking to changing a tire or fixing a toilet, the Experts at CANITUDE can have you saying “I can do that!” in no time. A quick video call, one to one, with one of our Experts will <a  (click)="scrollToDownload()" style="color: var(--mainColor); text-decoration: underline; cursor: pointer;">show you the way.</a></strong> </p>
            <p><strong></strong></p>
        </div>
    </div>
    <div class="tree-shape2"><img src="assets/img/tree-shape2-darkOrange" alt="image"></div>
    <!-- <div class="tree-shape3"><img src="assets/img/banner-shape6.png" alt="image"></div> -->
</div>