import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-faq',
  templateUrl: './ct-faq.component.html',
  styleUrls: ['./ct-faq.component.scss']
})
export class CtFaqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
