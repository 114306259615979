<!-- <div class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>Our Learners Feedback</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="testimonials-slides-two">
            <owl-carousel-o [options]="testimonialsSlidesTwo">
                <ng-template carouselSlide>
                    <div class="testimonials-item">
                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-12">
                                <h3>TRUST AND FUN.</h3>
                                <p>Everyone’s an expert about SOMETHING.
                                    You may have just the information that someone else is needing.
                                    Or you may need information and be embarrassed to ask a friend.
                                    CANITUDE gives you a way to learn from a trusted Expert… or to share your knowledge with a stranger in need.
                                </p>
                                <span>Python Developer</span>
                            </div>
                            <div class="col-lg-4 col-md-12 text-center">
                                <img src="assets/img/user7.png" alt="image">
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="testimonials-item">
                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-12">
                                <h3>SAFETY.</h3>
                                <p>One of the strengths of CANITUDE is that you can chat with your Expert whenever/wherever you 
                                    need to: in the moment of the emergency, in the garage with the tire that needs fixing, in the kitchen as you 
                                    learn to impress that new date with your cooking.  Your Expert can help!
                                </p>
                                <span>Developer</span>
                            </div>
                            <div class="col-lg-4 col-md-12 text-center">
                                <img src="assets/img/user9.png" alt="image">
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="testimonials-item">
                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-12">
                                <h3>Refer a Friend</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                <span>PHP Developer</span>
                            </div>
                            <div class="col-lg-4 col-md-12 text-center">
                                <img src="assets/img/user8.png" alt="image">
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div> -->


<!-- This section for Single Instructor Demo -->
<div
    class="instructor-feedback-area ptb-100"
>
    <div class="container">
        <div class="section-title instructor-title-color">
            <!-- <span class="sub-title">GO AT YOUR OWN PACE</span> -->
            <h2 class="playfair-display-font">Learners</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="instructor-feedback-slides">
            <owl-carousel-o [options]="instructorFeedbackSlides">
                <ng-template carouselSlide>
                    <div class="instructor-single-feedback-item">
                        <div class="client-info d-flex align-items-center">
                            <div class="title">
                                <h3 style="color: var( --mainColor);">TRUST AND FUN.</h3>
                                <!-- <span>Python Developer</span> -->
                            </div>
                        </div>
                        <p>
                            Everyone’s an expert about SOMETHING.
                            You may have just the information that someone else is needing.
                            Or you may need information and be embarrassed to ask a friend.
                            CANITUDE gives you a way to learn from a trusted Expert… or to share your knowledge with a stranger in need.
                        </p>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="instructor-single-feedback-item">
                        <div class="client-info d-flex align-items-center">
                            <div class="title">
                                <h3 style="color: var( --mainColor);">SAFETY.</h3>
                                <!-- <span>Python Developer</span> -->
                            </div>
                        </div>
                        <p>
                            One of the strengths of CANITUDE is that you can chat with your Expert whenever/wherever you 
                            need to: in the moment of the emergency, in the garage with the tire that needs fixing, in the kitchen as you 
                            learn to impress that new date with your cooking.  Your Expert can help!
                        </p>                        
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>