import { Component, ElementRef, OnInit, Renderer2, ViewChild } from "@angular/core";

@Component({
  selector: "app-ct-how-it-works",
  templateUrl: "./ct-how-it-works.component.html",
  styleUrls: ["./ct-how-it-works.component.scss"],
})
export class CtHowItWorksComponent implements OnInit {
  @ViewChild("appDownload", { static: true }) appDownload: ElementRef;
  

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  scrollToDownload() {
    const element = this.renderer.selectRootElement('#app-download', true);
    element.scrollIntoView({ behavior: 'smooth' });
  }
}
