<app-ct-navbar></app-ct-navbar>

<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>Account Guide</li>
      </ul>
      <h2>Account Guide</h2>
    </div>
  </div>
  <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div> -->
</div>

<div class="purchase-guide-area ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-12">
        <div class="purchase-guide-content">
          <p><i>Permanently delete or deactivate your CANitude account</i></p>
          <h3>1. Account Deletion</h3>
          <p>
            You can delete your account by navigating to the Profile tab and clicking the "Delete Account" button. Alternatively, you can follow these steps:
          </p>
          <ol>
            <li>Log in to your CANitude app.</li>
            <li>Tap on the Profile icon in the bottom right corner.</li>
            <li>In the Profile section, locate and click the "Delete Account" button.</li>
            <li>A confirmation popup will appear.</li>
            <li>The popup contains a warning about account deletion.</li>
            <li>Click "Delete Account" in the popup to permanently delete your account.</li>
          </ol>
          <h3>3. We can't delete your account</h3>
          <p>
            For security reasons, we are unable to delete accounts on your behalf. You must be logged into your account to request deletion. If you’ve forgotten your username or password, you can reset your password to regain access.
          </p>          
        </div>
      </div>
      <div class="col-lg-5 col-md-12">
        <div class="purchase-guide-content">
          <img src="../../../../assets/img/manage-accounts.svg" alt="image" />
        </div>
      </div>
    </div>
  </div>
</div>
<app-ct-footer></app-ct-footer>

