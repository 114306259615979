<div class="get-instant-courses-area">
    <div class="container">
        <div class="get-instant-courses-inner-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="get-instant-courses-content">
                        <span class="sub-title">Get Instant Access to The Free</span>
                        <h2>Self Development Course</h2>
                        <p>Odemy Self Development Course can assist you in bringing the significant changes in personal understanding and reshaping the confidence to achieve the best from your career! We trust that learning should be enjoyable, and only that can make substantial changes to someone!</p>
                        <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Start For Free<span></span></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="get-instant-courses-image">
                        <img src="assets/img/man.jpg" alt="image">
                        <div class="shape7"><img src="assets/img/shape4.png" alt="image"></div>
                        <div class="shape6"><img src="assets/img/shape6.png" alt="image"></div>
                    </div>
                </div>
            </div>
            <div class="shape5"><img src="assets/img/shape5.png" alt="image"></div>
        </div>
    </div>
</div>