<!-- Instructor Features Area -->
<div class="instructor-boxes-area pt-100 pb-70">
    <div class="container">
        <div class="section-title instructor-title-color">
            <span class="sub-title">EDUCATION FOR EVERYONE</span>
            <h2 class="el-messiri-font">Education is About Creating Leaders for Tomorrow</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-3 col-sm-6">
                <div class="instructor-boxes-card">
                    <img src="assets/img/instructor-home/boxes/boxes1.png" alt="image">
                    <h3 class="el-messiri-font">Latest Skills</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="instructor-boxes-card">
                    <img src="assets/img/instructor-home/boxes/boxes2.png" alt="image">
                    <h3 class="el-messiri-font">Industry Experts</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="instructor-boxes-card">
                    <img src="assets/img/instructor-home/boxes/boxes3.png" alt="image">
                    <h3 class="el-messiri-font">Online Courses</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="instructor-boxes-card">
                    <img src="assets/img/instructor-home/boxes/boxes4.png" alt="image">
                    <h3 class="el-messiri-font">One to One</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="instructor-boxes-shape">
        <img src="assets/img/instructor-home/boxes/shape.png" alt="image">
    </div>
</div>

<!-- Instructor Video Area -->
<div class="instructor-video-area">
    <div class="container">
        <div class="instructor-video-box">
            <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                <a
                    data-lg-size="1280-720"
                    data-src="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                    data-sub-html="<p>Watch Video</p>"
                    class="d-block"
                >
                    <div class="image">
                        <img src="assets/img/online-art/video/video.jpg" class="shadow" alt="video-image">
                    </div>
                    <div class="video-btn popup-youtube">
                        <i class="flaticon-play"></i>
                    </div>
                </a>
            </lightgallery>
        </div>
    </div>
    <div class="video-wave-shape">
        <img src="assets/img/instructor-home/video-wave.png" alt="image">
    </div>
    <div class="instructor-video-shape">
        <img src="assets/img/instructor-home/events/shape.png" alt="image">
    </div>
</div>