import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-app-download',
  templateUrl: './ct-app-download.component.html',
  styleUrls: ['./ct-app-download.component.scss']
})
export class CtAppDownloadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
