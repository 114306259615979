<div class="instructor-banner-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="instructor-banner-content">
                    <h1 class="el-messiri-font">Learn, Grow, Accomplish your goals</h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum suspendisse.</p>
                    <div class="banner-btn">
                        <a href="contact.html" class="default-btn"><i class="flaticon-user"></i>Get Started Now<span></span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="instructor-banner-image">
                    <img src="assets/img/instructor-home/banner/main.png" alt="image">
                    <div class="banner-dot-shape">
                        <img src="assets/img/instructor-home/banner/dot-shape.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="instructor-banner-shape-1">
        <img src="assets/img/instructor-home/banner/shape1.png" alt="image">
    </div>
    <div class="instructor-banner-shape-2">
        <img src="assets/img/instructor-home/banner/shape2.png" alt="image">
    </div>
    <div class="instructor-banner-shape-3">
        <img src="assets/img/instructor-home/banner/shape3.png" alt="image">
    </div>
</div>