import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-privacy-policy',
  templateUrl: './ct-privacy-policy.component.html',
  styleUrls: ['./ct-privacy-policy.component.scss']
})
export class CtPrivacyPolicyComponent implements OnInit {
  documentUrl = '../../../../assets/docs/PrivacyPolicy.pdf';
  
  constructor() { }

  ngOnInit(): void {
  }
}
