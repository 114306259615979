import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-ct-terms-and-conditions',
  templateUrl: './ct-terms-and-conditions.component.html',
  styleUrls: ['./ct-terms-and-conditions.component.scss']
})
export class CtTermsAndConditionsComponent implements OnInit {
  // documentUrl = '';
  documentUrl = '../../../../assets/docs/TermsOfConditions.pdf';
  objectURLStrAW: string;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }
}
