import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ct-safety',
  templateUrl: './ct-safety.component.html',
  styleUrls: ['./ct-safety.component.scss']
})
export class CtSafetyComponent  implements OnInit {
  constructor(private router: Router) {}
  ngOnInit(): void {}

  scrollAndNavigate() {
    this.router.navigate(["/home"], { queryParams: { id: "app-download" } });
  }
}