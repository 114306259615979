import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ct-about-us',
  templateUrl: './ct-about-us.component.html',
  styleUrls: ['./ct-about-us.component.scss']
})
export class CtAboutUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
